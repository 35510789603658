
import Vue from "vue";

import { Breadcrumb, Garbage } from "@/interfaces";

import { Route } from "vue-router";

import http from "@/http";

export interface DataType {
  garbage?: Garbage;
}

export default Vue.extend({
  data(): DataType {
    return {
      garbage: undefined
    };
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/garbages/${to.params.id}`;

    const { data } = await http.get<Garbage>(url);

    next((vm: DataType & Vue) => {
      vm.garbage = data;
    });
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.garbage == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: this.garbage.building.name,
          to: `/building/${this.garbage.building_id}`,
          disabled: false
        },
        {
          text: "ゴミステーション",
          disabled: true
        }
      ];
    }
  }
});
